var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submitContract}},[_c('a-row',{staticClass:"content",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":21}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Valor ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_total_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_total_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Preencha o valor.',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Valor","disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":2}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Parcelas")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_installments`,

                  {
                    initialValue: 2,
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_installments`,\n\n                  {\n                    initialValue: 2,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Parcelas","disabled":true}},_vm._l((_vm.billingTicket.installments),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item}},[_vm._v("\n                  "+_vm._s(item)+"\n                ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":1}},[_c('span',{staticClass:"relative c-pointer",staticStyle:{"top":"18px"},on:{"click":_vm.openBillingTicketInstallmentsModal}},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" Ver parcelas ")]),_vm._v(" "),_c('img',{attrs:{"src":require("../../../assets/images/dashboard/sales/view-installments.png"),"alt":"installments"}})],2)],1),_vm._v(" "),_c('a-modal',{attrs:{"title":'BOLETO - PARCELAS',"footer":false,"width":_vm.$root.windowWidth > 960 ? '300px' : '100%'},on:{"ok":function($event){_vm.openBillingTicketInstallments = false}},model:{value:(_vm.openBillingTicketInstallments),callback:function ($$v) {_vm.openBillingTicketInstallments=$$v},expression:"openBillingTicketInstallments"}},[(_vm.openBillingTicketInstallments)?_c('div',[_c('a-row',{staticClass:"f12",attrs:{"gutter":[20, 10]}},[_c('a-col',{attrs:{"span":6}},[_c('strong',[_vm._v(" # ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_c('strong',[_vm._v(" VALOR ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('strong',[_vm._v(" VENCIMENTO ")])])],1),_vm._v(" "),_c('a-row',{staticClass:"f12",attrs:{"gutter":[20, 10]}},[_c('a-col',{attrs:{"span":6}},[_c('strong',[_vm._v(" 1ª ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_vm._v("\n                    "+_vm._s(_vm.formatPricePtBr(
                        _vm.tempContract[
                          `payment_methods_billing_ticket_installment_1`
                        ]
                      ))+"\n                  ")]),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_vm._v("\n                    "+_vm._s(_vm.formatMultiDates(
                        _vm.tempContract[
                          `payment_methods_billing_ticket_installment_1_expiration_date`
                        ]
                      ))+"\n                  ")])],1),_vm._v(" "),_vm._l((_vm.billingTicketinstallments),function(field){return _c('a-row',{key:field,staticClass:"f12",attrs:{"gutter":[20, 10]}},[_c('a-col',{attrs:{"span":6}},[_c('strong',[_vm._v(" "+_vm._s(field.id)+"ª ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":8}},[_vm._v("\n                    "+_vm._s(_vm.formatPricePtBr(
                        _vm.tempContract[
                          `payment_methods_billing_ticket_installment_${field.id}`
                        ]
                      ))+"\n                  ")]),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_vm._v("\n                    "+_vm._s(_vm.formatMultiDates(
                        _vm.tempContract[
                          `payment_methods_billing_ticket_installment_${field.id}_expiration_date`
                        ]
                      ))+"\n                  ")])],1)})],2):_vm._e()])],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Emissor")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_tax_id`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_tax_id`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Emissor","disabled":!_vm.edit,"allowClear":""},on:{"change":_vm.calcBillingTicketInstallments}},_vm._l((_vm.billingTickets.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v("\n                  "+_vm._s(item.name)+"\n                ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Taxa ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_tax_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_tax_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Preencha o valor.',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Taxa","disabled":!_vm.edit}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Taxa ADM R$ (Referência)")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_adm_tax_value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_adm_tax_value`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Taxa ADM R$","disabled":true,"allowClear":""}},_vm._l((_vm.strToJson(
                    _vm.tempContract.payment_methods_billing_ticket_taxes_range
                  )),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":`${item.id}`}},[_vm._v("\n                  Parcela "+_vm._s(item.id)+" - "+_vm._s(item.value)+"\n                ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Taxa ADM R$ ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_adm_tax_new_value`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_adm_tax_new_value`,\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Preencha o valor.',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o novo valor","disabled":!_vm.edit},on:{"blur":_vm.calcBillingTicketInstallments}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Taxa ADM % (Referência)")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_adm_tax_percentage`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_adm_tax_percentage`,\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input select",attrs:{"placeholder":"Taxa ADM %","disabled":true,"allowClear":""}},_vm._l((_vm.strToJson(
                    _vm.tempContract.payment_methods_billing_ticket_taxes_range
                  )),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":`${item.id}`}},[_vm._v("\n                  Parcela "+_vm._s(item.id)+" -\n                  "+_vm._s(item.percentage)+"\n                ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v("Taxa ADM % ")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                  currency: {
                    prefix: '',
                    suffix: '',
                  },
                  distractionFree: false,
                  valueAsInteger: false,
                  precision: 2,
                  autoDecimalMode: false,
                  allowNegative: false,
                }),expression:"{\n                  currency: {\n                    prefix: '',\n                    suffix: '',\n                  },\n                  distractionFree: false,\n                  valueAsInteger: false,\n                  precision: 2,\n                  autoDecimalMode: false,\n                  allowNegative: false,\n                }"},{name:"decorator",rawName:"v-decorator",value:([
                  `payment_methods_billing_ticket_adm_tax_new_percentage`,
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Preencha o valor.',
                      },
                    ],
                  },
                ]),expression:"[\n                  `payment_methods_billing_ticket_adm_tax_new_percentage`,\n                  {\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Preencha o valor.',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira a nova porcentagem","disabled":!_vm.edit},on:{"blur":_vm.calcBillingTicketInstallments}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),(_vm.sale.status != 'Finalizado')?_c('a-col',{staticClass:"a-right",attrs:{"span":3}},[(_vm.edit)?_c('a-button',{staticClass:"ml-10 cancel",attrs:{"type":"primary"},on:{"click":function($event){return _vm.editRow('cancel')}}},[_c('a-icon',{attrs:{"type":"close"}})],1):_vm._e(),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 edit",attrs:{"type":"primary"},on:{"click":function($event){return _vm.editRow('edit')}}},[_c('a-icon',{attrs:{"type":"edit"}})],1):_vm._e(),_vm._v(" "),_c('a-popconfirm',{attrs:{"placement":"left","ok-text":"Sim","cancel-text":"Não"},on:{"confirm":function($event){return _vm.confirmAuditory()}}},[_c('template',{slot:"title"},[_c('p',[_vm._v("\n              Você confirma todos os valores e dados\n              "),_c('br'),_vm._v("inseridos neste recebimento? Após esta ação\n              "),_c('br'),_vm._v("\n              não será mais possível editar.\n            ")])]),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 update",attrs:{"html-type":"submit","type":"primary"}},[_c('a-icon',{attrs:{"type":"check"}})],1):_vm._e()],2),_vm._v(" "),(_vm.edit)?_c('a-button',{staticClass:"ml-10 save",staticStyle:{"padding":"0px 6px"},attrs:{"html-type":"submit","type":"primary"}},[_c('img',{attrs:{"src":require("../../../assets/images/dashboard/sales/disket.png"),"alt":"save"}})]):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }