<template>
  <div>
    <a-form :form="form" @submit="submitContract">
      <a-row class="content" :gutter="20">
        <a-col :span="21">
          <a-row :gutter="20">
            <a-col :span="3">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Valor </label>

                <a-input
                  class="travel-input"
                  placeholder="Valor"
                  v-currency
                  :disabled="true"
                  v-decorator="[
                    `payment_methods_billing_ticket_total_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha o valor.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="2">
              <a-form-item class="travel-input-outer">
                <label class="filled">Parcelas</label>

                <a-select
                  class="travel-input select"
                  placeholder="Parcelas"
                  :disabled="true"
                  v-decorator="[
                    `payment_methods_billing_ticket_installments`,

                    {
                      initialValue: 2,
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of billingTicket.installments"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="1">
              <span
                class="relative c-pointer"
                style="top: 18px"
                @click="openBillingTicketInstallmentsModal"
              >
                <a-tooltip>
                  <template slot="title"> Ver parcelas </template>

                  <img
                    src="../../../assets/images/dashboard/sales/view-installments.png"
                    alt="installments"
                  />
                </a-tooltip>
              </span>

              <a-modal
                :title="'BOLETO - PARCELAS'"
                :footer="false"
                :width="$root.windowWidth > 960 ? '300px' : '100%'"
                v-model="openBillingTicketInstallments"
                @ok="openBillingTicketInstallments = false"
              >
                <div v-if="openBillingTicketInstallments">
                  <a-row class="f12" :gutter="[20, 10]">
                    <a-col :span="6">
                      <strong> # </strong>
                    </a-col>
                    <a-col :span="8">
                      <strong> VALOR </strong>
                    </a-col>

                    <a-col :span="10">
                      <strong> VENCIMENTO </strong>
                    </a-col>
                  </a-row>
                  <a-row class="f12" :gutter="[20, 10]">
                    <a-col :span="6">
                      <strong> 1ª </strong>
                    </a-col>
                    <a-col :span="8">
                      {{
                        formatPricePtBr(
                          tempContract[
                            `payment_methods_billing_ticket_installment_1`
                          ]
                        )
                      }}
                    </a-col>

                    <a-col :span="10">
                      {{
                        formatMultiDates(
                          tempContract[
                            `payment_methods_billing_ticket_installment_1_expiration_date`
                          ]
                        )
                      }}
                    </a-col>
                  </a-row>
                  <a-row
                    class="f12"
                    v-for="field in billingTicketinstallments"
                    :key="field"
                    :gutter="[20, 10]"
                  >
                    <a-col :span="6">
                      <strong> {{ field.id }}ª </strong>
                    </a-col>
                    <a-col :span="8">
                      {{
                        formatPricePtBr(
                          tempContract[
                            `payment_methods_billing_ticket_installment_${field.id}`
                          ]
                        )
                      }}
                    </a-col>

                    <a-col :span="10">
                      {{
                        formatMultiDates(
                          tempContract[
                            `payment_methods_billing_ticket_installment_${field.id}_expiration_date`
                          ]
                        )
                      }}
                    </a-col>
                  </a-row>
                </div>
              </a-modal>
            </a-col>

            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Emissor</label>
                <a-select
                  class="travel-input select"
                  placeholder="Emissor"
                  :disabled="!edit"
                  @change="calcBillingTicketInstallments"
                  allowClear
                  v-decorator="[
                    `payment_methods_billing_ticket_tax_id`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of billingTickets.list"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="3">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Taxa </label>

                <a-input
                  class="travel-input"
                  placeholder="Taxa"
                  v-currency
                  :disabled="!edit"
                  v-decorator="[
                    `payment_methods_billing_ticket_tax_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Preencha o valor.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Taxa ADM R$ (Referência)</label>
                <a-select
                  class="travel-input select"
                  placeholder="Taxa ADM R$"
                  :disabled="true"
                  allowClear
                  v-decorator="[
                    `payment_methods_billing_ticket_adm_tax_value`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of strToJson(
                      tempContract.payment_methods_billing_ticket_taxes_range
                    )"
                    :key="index"
                    :value="`${item.id}`"
                  >
                    Parcela {{ item.id }} - {{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Taxa ADM R$ </label>
                <a-input
                  class="travel-input"
                  placeholder="Insira o novo valor"
                  v-currency
                  :disabled="!edit"
                  @blur="calcBillingTicketInstallments"
                  v-decorator="[
                    `payment_methods_billing_ticket_adm_tax_new_value`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Preencha o valor.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Taxa ADM % (Referência)</label>
                <a-select
                  class="travel-input select"
                  placeholder="Taxa ADM %"
                  :disabled="true"
                  allowClear
                  v-decorator="[
                    `payment_methods_billing_ticket_adm_tax_percentage`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) of strToJson(
                      tempContract.payment_methods_billing_ticket_taxes_range
                    )"
                    :key="index"
                    :value="`${item.id}`"
                  >
                    Parcela {{ item.id }} -
                    {{ item.percentage }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item class="travel-input-outer">
                <label :class="'filled'">Taxa ADM % </label>
                <a-input
                  class="travel-input"
                  placeholder="Insira a nova porcentagem"
                  @blur="calcBillingTicketInstallments"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: false,
                    allowNegative: false,
                  }"
                  :disabled="!edit"
                  v-decorator="[
                    `payment_methods_billing_ticket_adm_tax_new_percentage`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Preencha o valor.',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>

        <a-col v-if="sale.status != 'Finalizado'" class="a-right" :span="3">
          <a-button
            v-if="edit"
            @click="editRow('cancel')"
            class="ml-10 cancel"
            type="primary"
          >
            <a-icon type="close" />
          </a-button>

          <a-button
            v-if="!edit"
            @click="editRow('edit')"
            class="ml-10 edit"
            type="primary"
          >
            <a-icon type="edit" />
          </a-button>

          <a-popconfirm
            placement="left"
            ok-text="Sim"
            cancel-text="Não"
            @confirm="confirmAuditory()"
          >
            <template slot="title">
              <p>
                Você confirma todos os valores e dados
                <br />inseridos neste recebimento? Após esta ação
                <br />
                não será mais possível editar.
              </p>
            </template>
            <a-button
              v-if="!edit"
              html-type="submit"
              class="ml-10 update"
              type="primary"
            >
              <a-icon type="check" />
            </a-button>
          </a-popconfirm>

          <a-button
            v-if="edit"
            html-type="submit"
            class="ml-10 save"
            type="primary"
            style="padding: 0px 6px"
          >
            <img
              src="../../../assets/images/dashboard/sales/disket.png"
              alt="save"
            />
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";
import billingTicketsMixins from "@/mixins/billing-tickets/billingTicketsMixins.js";
export default {
  props: {
    tempContract: Object,
    sale: Object,
    id: Number,
    index: Number,
  },
  mixins: [formatThings, billingTicketsMixins],
  data() {
    return {
      edit: false,
      openBillingTicketInstallments: false,
      billingTicketinstallments: [],
      billingTicket: {
        installments: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        taxesRange: [],
      },
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempContract,
      }),
    };
  },
  mounted() {
    this.billingTickets.filters.status.selected = "Ativo";
    this.getBillingTickets();

    if (
      this.tempContract["payment_methods_billing_ticket_installments"] !=
      undefined
    ) {
      let installments = parseInt(
        this.tempContract["payment_methods_billing_ticket_installments"]
      );

      for (let i = 2; i < installments + 1; i++) {
        this.billingTicketinstallments.push({ id: i });
      }
    }

    let payments = JSON.parse(this.tempContract.payment_methods);

    if (payments.includes("billing-ticket")) {
      this.form.setFieldsValue({
        [`payment_methods_billing_ticket_total_value`]:
          this.tempContract[`payment_methods_billing_ticket_total_value`],
        [`payment_methods_billing_ticket_tax_id`]:
          this.tempContract[`payment_methods_billing_ticket_tax_id`],
        [`payment_methods_billing_ticket_installments`]:
          this.tempContract[`payment_methods_billing_ticket_installments`],
        [`payment_methods_billing_ticket_tax_value`]:
          this.tempContract[`payment_methods_billing_ticket_tax_value`],
        [`payment_methods_billing_ticket_adm_tax_value`]:
          this.tempContract[`payment_methods_billing_ticket_installments`],
        [`payment_methods_billing_ticket_adm_tax_percentage`]:
          this.tempContract[`payment_methods_billing_ticket_installments`],
        [`payment_methods_billing_ticket_adm_tax_new_percentage`]:
          this.tempContract[
            `payment_methods_billing_ticket_adm_tax_new_percentage`
          ],
        [`payment_methods_billing_ticket_adm_tax_new_value`]:
          this.tempContract[`payment_methods_billing_ticket_adm_tax_new_value`],
      });

      this.billingTicket.taxesRange =
        this.tempContract["payment_methods_billing_ticket_taxes_range"];
    }
  },
  methods: {
    editRow(type) {
      if (type == "edit") {
        this.edit = true;
      }

      if (type == "cancel") {
        this.edit = false;
      }
    },
    openBillingTicketInstallmentsModal() {
      this.openBillingTicketInstallments = true;
    },
    calcBillingTicketInstallments() {
      let id = this.form.getFieldValue(`payment_methods_billing_ticket_tax_id`);

      let tax = this.billingTickets.list.filter((e) => {
        return e.id == id;
      });

      if (tax[0] != undefined) {
        this.tempContract.payment_methods_billing_ticket_taxes_range =
          tax[0].taxes_range;

        this.billingTicket.taxesRange = tax[0].taxes_range;
      } else {
        this.tempContract.payment_methods_billing_ticket_taxes_range = "[]";
        this.billingTicket.taxesRange = "[]";
        setTimeout(() => {
          this.tempContract[`payment_methods_billing_ticket_tax_id`] = "";
        }, 100);
      }

      let installments = this.form.getFieldValue(
        `payment_methods_billing_ticket_installments`
      );

      let billingTicketValue = this.form.getFieldValue(
        `payment_methods_billing_ticket_total_value`
      );

      let taxesRange =
        this.tempContract["payment_methods_billing_ticket_taxes_range"];

      let selectedTax = "";

      if (taxesRange) {
        taxesRange = JSON.parse(taxesRange);
        selectedTax = taxesRange.filter((e) => {
          return e.id == installments;
        });
      } else {
        selectedTax = "";
        taxesRange = [];
      }

      let totalTaxes = 0;
      let totalPercentage = 0;
      taxesRange.forEach((tax) => {
        if (parseInt(tax.id) <= parseInt(installments)) {
          //setTimeout(() => {
          let thetax =
            this.form.getFieldValue(
              "payment_methods_billing_ticket_adm_tax_new_value"
            ) != undefined &&
            this.form.getFieldValue(
              "payment_methods_billing_ticket_adm_tax_new_value"
            ) != ""
              ? this.form
                  .getFieldValue(
                    "payment_methods_billing_ticket_adm_tax_new_value"
                  )
                  .replace(",", ".")
              : tax.value.replace("R$ ", "").replace(",", ".");
          let thePercentage =
            this.form.getFieldValue(
              "payment_methods_billing_ticket_adm_tax_new_percentage"
            ) != undefined &&
            this.form.getFieldValue(
              "payment_methods_billing_ticket_adm_tax_new_percentage"
            ) != ""
              ? this.form
                  .getFieldValue(
                    "payment_methods_billing_ticket_adm_tax_new_percentage"
                  )
                  .replace(",", ".")
              : tax.percentage.replace("%", "");

          totalTaxes += parseFloat(thetax);
          totalPercentage = parseFloat(thePercentage);

          //  }, 20);
        }
      });

      selectedTax;

      if (billingTicketValue) {
        billingTicketValue = billingTicketValue.replace(",", ".");
        billingTicketValue = parseFloat(billingTicketValue);
      }

      let thePercentageDiscount = 0;
      let totalTaxesBillingTicket = 0;

      thePercentageDiscount = (totalPercentage / 100) * billingTicketValue;

      totalTaxesBillingTicket = thePercentageDiscount + totalTaxes;

      this.tempContract[`payment_methods_billing_ticket_tax_value`] =
        totalTaxesBillingTicket ? this.formatPrice(totalTaxesBillingTicket) : 0;

      if (billingTicketValue) {
        let monthlyInstallments = [];
        let installmentsFields = [];

        if (installments == 1) {
          this.billingTicket.installmentsFields = [];

          this.form.setFieldsValue({
            [`payment_methods_billing_ticket_installment_1`]:
              billingTicketValue / installments,
          });
        } else {
          for (let i = 2; i < installments + 1; i++) {
            monthlyInstallments.push(billingTicketValue / installments);
            installmentsFields.push({ id: i });
          }

          this.billingTicket.installmentsFields = installmentsFields;

          this.form.setFieldsValue({
            [`payment_methods_billing_ticket_installment_1`]: this.formatPrice(
              monthlyInstallments[0]
            ),
          });

          this.billingTicket.installmentsFields.forEach((installment) => {
            setTimeout(() => {
              this.form.setFieldsValue({
                [`payment_methods_billing_ticket_installment_${installment.id}`]:
                  this.formatPrice(monthlyInstallments[0]),
              });
            }, 10);
          });
        }
      }

      this.form.setFieldsValue({
        [`payment_methods_billing_ticket_tax_value`]: totalTaxesBillingTicket
          ? this.formatPrice(totalTaxesBillingTicket)
          : 0,
      });
    },
    confirmAuditory() {
      let values = {};

      values.payment_methods_billing_ticket_audit_status = "Realizado";
      values.payment_methods_billing_ticket_audit_user_id =
        this.$store.state.userData.id;
      values.payment_methods_billing_ticket_taxes_range =
        this.billingTicket.taxesRange;
      values.id = this.tempContract.id;
      values.sale_id = this.sale.id;
      values.module = "sale";
      values.action = "update-contract-billing-ticket-status-in-sale";
      values.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.tempContract["payment_methods_billing_ticket_audit_status"] =
        "Realizado";
      this.tempContract["payment_methods_billing_ticket_audit_user_id"] =
        this.$store.state.userData.id;
      values["sale_receive_active_accordeon"] = this.index;

      this.$http
        .post("/contract/update-multi-meta", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$emit("updateTempContract", this.tempContract);
          this.edit = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    submitContract(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        values.id = this.tempContract.id;
        values.sale_id = this.sale.id;
        values.module = "sale";
        values.payment_methods_billing_ticket_taxes_range =
          this.billingTicket.taxesRange;
        values.action = "update-contract-billing-ticket-in-sale";
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        values.sale_receive_active_accordeon = this.index;

        if (!err) {
          this.$http
            .post("/contract/update-multi-meta", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("updateTempContract", this.tempContract);
              this.edit = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        } else {
          this.$message.error("Prrencha todos os campos.");
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.content
  padding: 20px 20px 0
  .edit
    background: #f27935
    border-color: #f27935
    padding: 5px 10px
  .cancel
    background: #59595b
    border-color: #59595b
    padding: 5px 10px
  .update
    background: #2ecc71
    border-color: #2ecc71
    padding: 5px 10px
  .save
    background: #00acec
    border-color: #00acec
    padding: 0 6px
</style>
